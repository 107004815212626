import { orderApi } from '@/api'
import Vue from 'vue'
import { getCookie } from "@/utils/cookie";
import VueI18n from 'vue-i18n'
import EN from "@/locale/en.json";
import RU from "@/locale/ru.json";

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: getCookie('locale'),
    silentTranslationWarn: true,
    messages: {
        en: EN,
        ru: RU
    }
})
export default {
    state: {
        orders: [],
        ordersForMap: [],
        orderItem: null,
        rejectionReasons: [],
        orderComments: [],
        orderParticipants: [],
        proposedParticipants: [],
        participationRequests: [],
        meta: {},
        orderDamage: [],
        orderDamageRanges: [
            {id: 1, color: '#02AD21', text: i18n.t('closed-requests'), status: 'Closed'},
            {id: 2, color: '#FD9F32', text: i18n.t('requests-under-moderation'), status: 'Moderation'},
            {id: 3, color: '#00A2F5', text: i18n.t('sustainable-development-projects-2'), status: 'ESG'}
        ],
        orderMapZoom: 10
    },

    getters: {
        getOrders: (state) => state.orders,
        getMapOrders: (state) => state.ordersForMap,
        getOrderItem: (state) => state.orderItem,
        getRejectionReasons: (state) => state.rejectionReasons,
        getOrderComments: (state) => state.orderComments,
        getOrderParticipants: (state) => state.orderParticipants,
        getProposedParticipants: (state) => state.proposedParticipants,
        getParticipationRequests: (state) => state.participationRequests,
        getMeta: (state) => state.meta,
        getOrderDamage: (state) => state.orderDamage,
        getDamageRanges: (state) => state.orderDamageRanges,
        getMapOrderZoom: (state) => state.orderMapZoom
    },

    mutations: {
        setOrders(state, payload) {
            state.orders = payload
        },
        setMapOrders(state, payload) {
            state.ordersForMap = payload
        },
        setSortBy(state, payload) {
            state.sortBy = payload
        },
        setOrderItem(state, payload) {
            state.orderItem = payload
        },
        changeOrderItem(state, payload) {
            state.orderItem = Object.assign(state.orderItem, payload)
        },
        setsRejectionReasons(state, payload) {
            state.rejectionReasons = payload
        },
        sendOrderComments(state, payload) {
            state.orderComments = payload
        },
        sendOrderCommentsMore(state, payload) {
            state.orderComments = state.orderComments.concat(payload)
        },
        addOrderComments(state, payload) {
            state.orderComments.unshift(payload)
        },
        getOrderParticipants(state, payload) {
            state.orderParticipants = payload
        },
        setOrderParticipants(state, payload) {
            state.orderParticipants = state.orderParticipants.concat(payload)
        },
        setProposedParticipants(state, payload) {
            state.proposedParticipants = payload
        },
        setProposedParticipantsMore(state, payload) {
            state.proposedParticipants = state.proposedParticipants.concat(payload)
        },
        setParticipationRequests(state, payload) {
            state.participationRequests = payload
        },
        setMeta (state, payload) {
            state.meta = payload
        },
        setOrderDamage (state, payload) {
            state.orderDamage = payload
        },
        setDamageRanges (state, payload) {
            payload.reverse().forEach(el => {
                let data = {
                    text: el.name,
                    id: state.orderDamageRanges.length+1,
                    color: el.colorCode,
                    status: 'Accepted',
                    minValue: el.minValue,
                    maxValue: el.maxValue,
                    damageRangeId: el.id
                }
                state.orderDamageRanges.unshift(data)
            })
        },
        setOrderMapZoom (state, payload) {
            state.orderMapZoom = payload
        }
    },

    actions: {
        reqGetOrders(state, params) {
            return new Promise((resolve, reject) => {
                orderApi.getOrders(params).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderId({ commit }, id) {
            commit('setOrderItem', {})
            return new Promise((resolve, reject) => {
                orderApi.getOrderId(id).then((res) => {
                    commit('setOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        patchOrderId({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.patchOrderId(data).then((res) => {
                    commit('setOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderLike({ commit }, id) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderLike(id).then((res) => {
                    commit('changeOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderDisLike({ commit }, id) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderDisLike(id).then((res) => {
                    commit('changeOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendRejectionReasons({ commit }) {
            return new Promise((resolve, reject) => {
                orderApi.sendRejectionReasons().then((res) => {
                    commit('setsRejectionReasons', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderComments({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderComments(data).then((res) => {
                    commit('sendOrderComments', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderCommentsMore({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderComments(data).then((res) => {
                    commit('sendOrderCommentsMore', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        postOrderComment({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.postOrderComment(data).then((res) => {
                    commit('addOrderComments', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderParticipants({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderParticipants(data).then((res) => {
                    commit('getOrderParticipants', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderParticipantsMore({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderParticipants(data).then((res) => {
                    commit('setOrderParticipants', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderProposedParticipants({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderProposedParticipants(data).then((res) => {
                    commit('setProposedParticipants', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderProposedParticipantsMore({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderProposedParticipants(data).then((res) => {
                    commit('setProposedParticipantsMore', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },


        // moderator
        sendOrderAccept({ commit }, id) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderAccept(id).then((res) => {
                    commit('changeOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderReject({ commit }, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderReject(data).then((res) => {
                    commit('changeOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOrderClose({ commit }, id) {
            return new Promise((resolve, reject) => {
                orderApi.sendOrderClose(id).then((res) => {
                    commit('changeOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendParticipantsApprove(state, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendParticipantsApprove(data).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendParticipantsDeny(state, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendParticipantsDeny(data).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        createParticipants(state, data) {
            return new Promise((resolve, reject) => {
                orderApi.createParticipants(data).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendParticipationRequests( {commit}, data) {
            return new Promise((resolve, reject) => {
                orderApi.sendParticipationRequests(data).then((res) => {
                    commit('setParticipationRequests', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        createParticipationRequests(state, data) {
            return new Promise((resolve, reject) => {
                orderApi.createParticipationRequests(data).then((res) => {
                    // commit('setParticipationRequests', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteOrderParticipants(state, data) {
            return new Promise((resolve, reject) => {
                orderApi.deleteOrderParticipants(data).then((res) => {
                    state.proposedParticipants = []
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editDamage(state, payload) {
            return new Promise((resolve, reject) => {
                orderApi.editDamage(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderSubscribe(state, payload) {
            return new Promise((resolve, reject) => {
                orderApi.orderSubscribe(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderUnsubscribe(state, payload) {
            return new Promise((resolve, reject) => {
                orderApi.orderUnsubscribe(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderConfirm(state, payload) {
            return new Promise((resolve, reject) => {
                orderApi.orderConfirm(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderRefuse(state, payload) {
            return new Promise((resolve, reject) => {
                orderApi.orderRefuse(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderRequest(state, payload) {
            return new Promise((resolve, reject) => {
                orderApi.orderRequest(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderDamage({commit}, data) {
            return new Promise((resolve, reject) => {
                orderApi.orderDamage(data).then((res) => {
                    commit('setOrderDamage', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderDownload(store, data) {
            return new Promise((resolve, reject) => {
                orderApi.orderDownload(data).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderDamageRanges({commit}, data) {
            return new Promise((resolve, reject) => {
                orderApi.orderDamageRanges(data).then((res) => {
                    commit('setDamageRanges', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderDownloadPdf(store, data) {
            return new Promise((resolve, reject) => {
                orderApi.orderDownloadPdf(data).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        orderToRemoderation({commit}, data) {
            return new Promise((resolve, reject) => {
                orderApi.orderToRemoderation(data).then((res) => {
                    commit('changeOrderItem', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
